import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import PanelDev from '../panels/dev/PanelDev';

import PanelOverview from 'panels/home/PanelOverview';
import PanelMain from 'panels/home/PanelMain';

export default function LayoutHome() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'overview':
                                return (<PanelOverview onToggle={toggleMenu} />);
                            case 'main':
                                return (<PanelMain onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
