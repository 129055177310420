import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceDept {
    lookupService = new APIServiceLookUp();

    async setDept(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}dept/setDept`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateDept(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}dept/updateDept`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllDept(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}dept/getAllDept`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDeptById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}dept/getDeptById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDeptOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}dept/getDeptOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delDept(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}dept/delDept`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}