import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceStaff {
    lookupService = new APIServiceLookUp();

    async staffRegister(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partnerstaff/staffRegister`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllStaff(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partnerstaff/getAllStaff`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getStaffById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partnerstaff/getStaffById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateStaff(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}partnerstaff/updateStaff`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    // async disableUser(body, token) {
    //     var serviceType = "POST";
    //     var url = `${API_URL}staff/disableUser`;
    //     var apiService = this.lookupService.getAPIService(serviceType);
    //     return await apiService.doCall(url, body, token);
    // }

    // async deleteStaffById(body, token) {
    //     var serviceType = "POST";
    //     var url = `${API_URL}staff/deleteStaffById`;
    //     var apiService = this.lookupService.getAPIService(serviceType);
    //     return await apiService.doCall(url, body, token);
    // }



    // async setStaff(body, token) {
    //     var serviceType = "POST";
    //     var url = `${API_URL}staff/setStaff`;
    //     var apiService = this.lookupService.getAPIService(serviceType);
    //     return await apiService.doCall(url, body, token);
    // }
}