import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { partnerMeetStateAtom, partnerMeetConfigAtom } from '../core/config/atoms';

import { Dialog } from '@headlessui/react';
import Lottie from 'lottie-react';

import PanelDev from 'panels/dev/PanelDev';
import PanelSelectDept from 'panels/meet/PanelSelectDept';
import PanelInitMeet from 'panels/meet/PanelInitMeet';
import PanelMeet from 'panels/meet/PanelMeet';
import PanelThankYou from 'panels/meet/PanelThankYou';

import ServiceDept from 'services/serviceDept';

import animPage from '../assets/anim/anim-pageload.json';

export default function LayoutMeet({ partner }) {

    const location = useLocation();

    const [meetState, setMeetState] = useRecoilState(partnerMeetStateAtom);
    const [, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);

    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);

    const deptService = new ServiceDept();

    const gotoLogin = () => {
        window.location.href = 'https://app.ishcallsign.com';
    }

    const loadData = () => {
        (async function () {
            if (location.pathname.includes("connect")) {
                var payload = parseUrlPath(location.pathname);
                if (payload.code != "") {
                    if (payload.did != "") {
                        let body = {
                            "did": payload.did
                        };
                        var res = await deptService.getDeptById(body, '');
                        if (res.status) {
                            setMeetConfig({ 'dept': { title: res.data.title, disc: res.data.disc, id: res.data._id, tag: '1' } });
                            setMeetState(1);
                            setLoading(true);
                        } else {
                            setRedirect(true);
                        }
                    } else {
                        setMeetConfig({ 'dept': { title: 'general', disc: 'General enquiry department', id: 'na', tag: '1' } });
                        setMeetState(1);
                        setLoading(true);
                    }
                }
            } else {
                setLoading(true);
            }
        })();
    }

    function parseUrlPath(urlPath) {
        const segments = urlPath.split('/').filter(Boolean);
        let result = { code: "", did: "" };
        if (segments[0] === 'connect') {
            if (segments.length === 2) {
                result = { code: segments[1], did: "" };
            } else if (segments.length === 3) {
                result = { code: segments[1], did: segments[2] };
            }
        }
        return result;
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Dialog open={redirect} onClose={() => setRedirect(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex flex-col items-center justify-center'>
                        <p className='my-6 text-yellow-600 font-medium text-lg text-center px-12'>This partner link your trying to connect is ethier moved to some other link or invalid</p>
                        <button type="button" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" onClick={() => { gotoLogin(); }}>
                            OK
                        </button>
                    </div>
                </div>
            </Dialog>
            {
                !loading && <>
                    <div className='flex items-center justify-center w-full h-screen'>
                        <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                    </div>
                </>
            }
            {
                loading && <div className='w-full min-h-[84vh] md:min-h-[74vh] px-[2%] md:px-[10%] bg-gray-100 flex items-center justify-center'>
                    {
                        (() => {
                            switch (meetState) {
                                case 0:
                                    return (<PanelSelectDept partner={partner} />);
                                case 1:
                                    return (<PanelInitMeet partner={partner} />);
                                case 2:
                                    return (<PanelMeet partner={partner} />);
                                case 3:
                                    return (<PanelThankYou partner={partner} />);
                                default:
                                    return (<PanelDev />);
                            }
                        })()
                    }
                </div>
            }
        </>
    )
}
