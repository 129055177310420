import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import moment from 'moment';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import animPage from '../../../assets/anim/anim-pageload.json';

import ServiceProfiles from 'services/serviceProfiles';
import ServiceUtility from 'services/serviceUtility';

import PaneTicket from '../panes/PaneTicket';
import PaneAttandance from '../panes/PaneAttandance';

import PanelDev from '../../dev/PanelDev';

export default function ComponentStaffInfo({ cid }) {

    const [token] = useRecoilState(tokenAtom);

    const [user, setUser] = useState({});

    const [loading, setLoading] = useState(true);

    const [tab, setTab] = useState('ticket');

    const profileService = new ServiceProfiles();
    const utilityService = new ServiceUtility();

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                'sid': cid
            };
            var res = await profileService.getPartnerStaffById(body, token);
            if (res['status']) {
                setUser(res.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, [cid]);

    return (
        <div>
            {
                loading && <div className='flex items-center justify-center w-full h-screen'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <div>
                    <div className='h-[320px] bg-blue-50 px-4 relative'>
                        <div className='h-[220px] bg-blue-600 rounded-b-md'></div>
                        <div className='w-[180px] h-[180px] bg-white border-[6px] border-blue-50 rounded-full absolute top-[120px] ml-8'>
                            <img src={utilityService.getIsObjectEmpty(user['profileMedia']) ? "https://storage.googleapis.com/ish-prod/ish-assets/staff.png" : user['profileMedia']['fileurl']} alt='Profile Pic' className='w-full h-full rounded-full' />
                        </div>
                        <h1 className='ml-[220px] text-4xl font-medium absolute'>{utilityService.getFullName(user["user"])}</h1>
                    </div>
                    <div className='bg-gray-50 px-12 py-4'>
                        <div className='bg-white w-full shadow rounded-md p-4 flex'>
                            <div className='w-[30%]'>
                                <h2 className='text-xl font-medium'>About</h2>
                                <ul className='mt-2'>
                                    <li className='text-sm font-medium py-1 px-2 rounded-md text-blue-600 bg-blue-50 hover:bg-blue-50'>Personal Information</li>
                                </ul>
                            </div>
                            <div className='w-[1px] bg-gray-200 mx-2'></div>
                            <div className='flex-grow'>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Email Address</div>
                                    <div className="text-slate-950 text-sm font-medium">{user.user.email}</div>
                                </div>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Phone Number</div>
                                    <div className="text-slate-950 text-sm font-medium">{user.user.phone === "" ? "-" : `+91 ${user.user.phone}`}</div>
                                </div>
                                <div className='p-2'>
                                    <div className="text-slate-400 text-xs font-normal">Date of Birth</div>
                                    <div className="text-slate-950 text-sm font-medium">{user.user.dob === null ? "-" : moment(user.user['dob']).format('DD MMM yyyy')}</div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <h2 className='text-xl font-medium'>Activity</h2>
                            <div class="w-full mt-2">
                                <div className="py-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'ticket' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('ticket') }}>Ticket Report</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tab === 'attandance' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('attandance') }}>Attendance Report</button></li>
                                    </ul>
                                </div>
                            </div>
                            {
                                (() => {
                                    switch (tab) {
                                        case 'ticket':
                                            return (<PaneTicket role={user.role} pid={user.partnerId} userId={user._id} user={user.user} />);
                                        case 'attandance':
                                            return (<PaneAttandance cid={cid} />);
                                        default:
                                            return (<PanelDev />);
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
