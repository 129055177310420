import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom, attandanceAtom } from '../../../core/config/atoms';

import Lottie from 'lottie-react';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

import ServiceAttendance from '../../../services/serviceAttandance';
import ServiceUtility from '../../../services/serviceUtility';

export default function PaneAttandance(props) {

    const [token] = useRecoilState(tokenAtom);
    const [attandance, setAttandance] = useRecoilState(attandanceAtom);

    const [submit, setSubmit] = useState(false);

    const localStreamRef = useRef();
    const canvasRef = useRef();

    const attendanceService = new ServiceAttendance();
    const utilityService = new ServiceUtility();

    const markAttandance = () => {
        setSubmit(true);
        const canvas = canvasRef.current;
        const video = localStreamRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
            var formDataA = new FormData();
            formDataA.append("path", 'attendance');
            formDataA.append("doc", blob, 'intss.jpeg');
            utilityService.uploadFile(formDataA, token).then((res) => {
                let body = {
                    'aid': attandance._id,
                    'data': {
                        "profileMedia": res.data,
                        "status": 'open',
                    }
                }
                attendanceService.updateAttendance(body, token).then((resA) => {
                    setAttandance(resA.data);
                    setSubmit(false);
                    props.Open();
                });
            });
        }, 'image/jpeg');
    }

    const initLocalVideo = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current.srcObject = stream;
    }

    useEffect(() => {
        initLocalVideo();
    }, []);

    return (
        <div className='col-span-12 h-full bg-gray-50 rounded-lg flex flex-col items-center justify-center'>
            <div className='w-[640px] h-[480px] rounded-xl'>
                <div className='w-full h-full relative'>
                    <video ref={localStreamRef} autoPlay playsInline muted className='border-2 border-prime rounded-xl object-fill absolute inset-0 w-full h-full' />
                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                </div>
            </div>
            <div className='w-full h-[10%] flex items-center justify-center'>
                <div className='bg-prime cursor-pointer h-10 w-[160px] mr-2 text-white text-sm font-medium flex items-center justify-center rounded-md' onClick={() => { markAttandance(); }}>
                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                    {!submit && "MARK ATTENDANCE"}
                </div>
            </div>
        </div>
    )
}
