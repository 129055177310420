import React from 'react';

import { Star } from "lucide-react";

export default function ReviewStars({ rating }) {

    return (
        <div className="text-sm flex items-center mt-1">
            {rating}&nbsp;<Star size={14} className='text-yellow-500' />
        </div>
    )
}
