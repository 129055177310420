import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../core/config/atoms';

import { toast } from 'react-toastify';
import grapesjs from 'grapesjs';
import grapesPlugBlocksBasic from 'grapesjs-blocks-basic';
import 'grapesjs/dist/css/grapes.min.css';

import ServiceSubscription from 'services/serviceSubscription';

export default function PanelPageStyle() {

    const editorRef = useRef(null);

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const navigate = useNavigate();
    const subscriptionService = new ServiceSubscription();

    const handleSave = () => {
        const allCSS = editorRef.current.getCss();
        const components = editorRef.current.getComponents();
        const payLoad = JSON.stringify({ head: components.at(0).toHTML(), foot: components.at(2).toHTML(), css: allCSS });

        var body = {
            'pid': userData.partnerId,
            'data': payLoad
        };

        subscriptionService.updatePageStyle(body, token).then((res) => {
            if (res) {
                toast.success('Partner page style updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    };

    const loadData = () => {
        (async function () {
            let body = {
                "pid": userData.partnerId,
            };
            var res = await subscriptionService.getSubscriptionById(body, token);
            if (res['status']) {
                const components = editorRef.current.getComponents();
                components.reset();
                if (res.data.pageData === "") {
                    editorRef.current.addComponents(`<header style="font-family: 'Roboto'; background-color: #f8f8f8; color: black; text-align: center; padding: 10px 0;">
                        <h1 style="margin: 0;">Header Title</h1>
                        <p style="margin: 0;">This is the header section.</p>
                        </header>`);

                    const mainComp = editorRef.current.addComponents(`<main id="no-edit-zone" style="min-height: 600px; background-color: #93c5fd;display: flex;justify-items: center;width: 100%;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;"></main>`)[0];
                    mainComp.set('draggable', false);
                    mainComp.set('droppable', false);
                    mainComp.set('editable', false);
                    mainComp.set('removable', false);
                    mainComp.set('resizable', false);
                    mainComp.set('selectable', false);
                    mainComp.set('stylable', false);
                    mainComp.set('highlightable', false);
                    const tempT = mainComp.append(`<h1 style="font-family: 'Roboto'; color: #1d4ed8;">No Edit Zone</h1>`)[0];
                    tempT.set('draggable', false);
                    tempT.set('droppable', false);
                    tempT.set('editable', false);
                    tempT.set('removable', false);
                    tempT.set('resizable', false);
                    tempT.set('selectable', false);
                    tempT.set('stylable', false);
                    tempT.set('highlightable', false);

                    editorRef.current.addComponents(`<footer style="font-family: 'Roboto'; background-color: #f1f1f1; color: #333; text-align: center; padding: 10px 0; width: 100%;">
                        <p style="margin: 0;">This is the footer section.</p>
                        <p style="margin: 0;">&copy; 2024 Your Company. All rights reserved.</p>
                        </footer>`);
                } else {
                    const payload = JSON.parse(res.data.pageData);

                    editorRef.current.setStyle(payload.css);
                    editorRef.current.addComponents(payload.head);

                    const mainComp = editorRef.current.addComponents(`<main id="no-edit-zone" style="min-height: 600px; background-color: #93c5fd;display: flex;justify-items: center;width: 100%;flex-direction: row;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;"></main>`)[0];
                    mainComp.set('draggable', false);
                    mainComp.set('droppable', false);
                    mainComp.set('editable', false);
                    mainComp.set('removable', false);
                    mainComp.set('resizable', false);
                    mainComp.set('selectable', false);
                    mainComp.set('stylable', false);
                    mainComp.set('highlightable', false);
                    const tempT = mainComp.append(`<h1 style="font-family: 'Roboto'; color: #1d4ed8;">No Edit Zone</h1>`)[0];
                    tempT.set('draggable', false);
                    tempT.set('droppable', false);
                    tempT.set('editable', false);
                    tempT.set('removable', false);
                    tempT.set('resizable', false);
                    tempT.set('selectable', false);
                    tempT.set('stylable', false);
                    tempT.set('highlightable', false);

                    editorRef.current.addComponents(payload.foot);
                }
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        editorRef.current = grapesjs.init({
            container: '#gjs',
            height: '100vh',
            width: 'auto',
            fromElement: false,
            storageManager: false,
            plugins: [grapesPlugBlocksBasic],
            pluginsOpts: {
                [grapesPlugBlocksBasic]: {}
            },
        });

        loadData();

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
            }
        };
    }, []);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <div className="intro-y">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-2xl font-sans font-bold text-gray-800 my-2">Partner Page Customization</h3>
                        <button className="h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white mr-4 text-sm" onClick={() => { handleSave(); }}>SAVE PAGE</button>
                    </div>
                    <hr />
                </div>
            </div >
            <div className='w-full h-screen rounded' id="gjs">

            </div>
        </div>
    )
}
