import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";

import { motion } from "framer-motion";
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import moment from 'moment';
import axios from 'axios';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceDept from 'services/serviceDept';
import ServiceUtility from 'services/serviceUtility';
import ServiceSubscription from 'services/serviceSubscription';

import { API_URL } from 'services/models/AppConstent';

import fileIcon from '../../assets/image/file.png';

export default function PanelMarLang(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const [banner, setBanner] = useState(null);

    const [data, setData] = useState([]);

    const [oldData, setOldData] = useState({});

    const [editId, setEditId] = useState('');
    const [oldUrl, setOldURL] = useState('');

    const [progress, setProgress] = useState(0);

    const [isOpenMain, setIsOpenMain] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const deptService = new ServiceDept();
    const utilityService = new ServiceUtility();
    const subscriptionService = new ServiceSubscription();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        disc: Yup.string().required('This information is required'),
        index: Yup.number().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            title: '',
            disc: '',
            index: 0
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (isOpenMain && !isUpdate) {
                setSubmit(true);
                if (banner !== null) {
                    (async function () {
                        setProgress(0);
                        var formDataA = new FormData();
                        formDataA.append("path", 'deptBanner');
                        formDataA.append("doc", banner);

                        var url = `${API_URL}file/uploadFile`;
                        var requestHeaders = {
                            'Content-type': 'multipart/form-data',
                            'Accept': '*/*',
                            'Authorization': `bearer ${token}`,
                        };
                        const response = await axios.post(url, formDataA, {
                            headers: requestHeaders,
                            onUploadProgress: (progressEvent) => {
                                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                                setProgress(progress);
                            },
                        });

                        let bodyRes = {
                            "pid": userData.partnerId,
                            "title": values.title,
                            "disc": values.disc,
                            "index": values.index,
                            "media": response.data.data,
                        }
                        deptService.setDept(bodyRes, token).then((res) => {
                            setSubmit(false);
                            if (res.status) {
                                toast.success('New Department Added', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                loadData();
                            } else {
                                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                            setIsOpenMain(false);
                            setIsUpdate(false);
                            setBanner(null);
                            setOldURL('');
                            setOldData({});
                            handleReset();
                        });
                    })();
                } else {
                    let body = {
                        "pid": userData.partnerId,
                        "title": values.title,
                        "disc": values.disc,
                        "index": values.index,
                        "media": {}
                    }
                    deptService.setDept(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            toast.success('New Department Added', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            loadData();
                        } else {
                            toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setIsOpenMain(false);
                        setIsUpdate(false);
                        setBanner(null);
                        setOldURL('');
                        setOldData({});
                        handleReset();
                    });
                }
            }

            if (isOpenMain && isUpdate) {
                setSubmit(true);
                if (banner !== null) {
                    (async function () {
                        setProgress(0);
                        var formDataA = new FormData();
                        formDataA.append("path", oldUrl);
                        formDataA.append("doc", banner);

                        var url = `${API_URL}file/replaceFile`;
                        var requestHeaders = {
                            'Content-type': 'multipart/form-data',
                            'Accept': '*/*',
                            'Authorization': `bearer ${token}`,
                        };
                        const response = await axios.post(url, formDataA, {
                            headers: requestHeaders,
                            onUploadProgress: (progressEvent) => {
                                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                                setProgress(progress);
                            },
                        });

                        let body = {
                            "oid": editId,
                            "data": {
                                "title": values.title,
                                "disc": values.disc,
                                "index": values.index,
                                "media": response.data.data,
                            },
                        }
                        deptService.updateDept(body, token).then((res) => {
                            setSubmit(false);
                            if (res.status) {
                                toast.success('Department Updated', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                loadData();
                            } else {
                                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                            setIsOpenMain(false);
                            setIsUpdate(false);
                            setBanner(null);
                            setOldURL('');
                            setOldData({});
                            setEditId('');
                            handleReset();
                        });
                    })();
                } else {
                    let body = {
                        "oid": editId,
                        "data": {
                            "title": values.title,
                            "disc": values.disc,
                            "index": values.index,
                        },
                    }
                    deptService.updateDept(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            toast.success('Department Updated', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            loadData();
                        } else {
                            toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setIsOpenMain(false);
                        setIsUpdate(false);
                        setBanner(null);
                        setOldURL('');
                        setOldData({});
                        setEditId('');
                        handleReset();
                    });
                }
            }
        }
    });

    const copyLink = (id) => {
        var body = {
            'pid': userData.partnerId
        }
        subscriptionService.getSubscriptionById(body, token).then((res) => {
            let url = `https://partner.ishcallsign.com/connect/${res.data.links}/${id}`;
            navigator.clipboard.writeText(url).then(() => {
                toast.success('Link copied to clipboard!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }).catch((error) => {
                toast.error(`Failed to copy the link: ${error}`, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            });
        });
    }

    const editMar = (item) => {
        let data = {
            title: item.title,
            disc: item.disc,
            index: item.index,
        }
        if (item.media !== undefined) {
            setOldURL(item.media.fileurl);
            setOldData(item.media);
        } else {
            setOldURL('');
            setOldData({});
        }
        setEditId(item._id);
        setIsUpdate(true);
        setValues(data);
        setIsOpenMain(true);
    }

    const delMar = (id, media) => {
        if (media !== undefined) {
            let body = {
                'path': media.fileurl
            }
            utilityService.deleteFile(body, token).then((res) => {
                let data = {
                    "oid": id
                }
                deptService.delDept(data, token).then((res) => {
                    if (res.status) {
                        toast.success('Department Deleted', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        loadData();
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            });
        } else {
            let data = {
                "oid": id
            }
            deptService.delDept(data, token).then((res) => {
                if (res.status) {
                    toast.success('Department Deleted', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    loadData();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    }

    const loadData = () => {
        (async function () {
            let body = {
                "pid": userData.partnerId,
            };
            var res = await deptService.getAllDept(body, token);
            if (res['status']) {
                const sortedData = res.data.slice().sort((a, b) => {
                    return a.index - b.index;
                });
                setData(sortedData);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className='h-screen w-full'>
            <Dialog open={isOpenMain} onClose={() => setIsOpenMain(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Department &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenMain(false);
                            setIsUpdate(false);
                            setBanner(null);
                            handleReset();
                            document.getElementById("mar-form").reset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} id="mar-form">
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Department<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" name="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. general enquiry" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Index <sup className="text-red-600">*</sup></label>
                            <input type="text" id="index" name="index" value={values.index} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. 1" onChange={handleChange} />
                            {(errors.index && touched.index) && <p className='text-xs text-red-400 mt-1'>{errors.index}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Description<sup className="text-red-600">*</sup></label>
                            <textarea id="disc" name="disc" value={values.disc} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. information about dept." onChange={handleChange} />
                            {(errors.disc && touched.disc) && <p className='text-xs text-red-400 mt-1'>{errors.disc}</p>}
                        </div>
                        <div className="mb-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Department Banner&nbsp;<span className="text-gray-400 text-xs">(optional)</span>
                                </label>
                            </div>
                            {
                                (banner === null && oldUrl === "") && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={(e) => setBanner(e.target.files[0])} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            600w x 400h<br />
                                            PNG, JPG, MP4 up to 50mb
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                banner !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={banner.type.includes("video") ? fileIcon : URL.createObjectURL(banner)} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {banner.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setBanner(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                            {
                                (banner === null && oldUrl !== "") && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={oldData.type.includes("video") ? fileIcon : oldUrl} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {oldData.filename}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setBanner(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            submit && <div className='my-3'>
                                <p className='text-xs text-gray-400'>Uploading....</p>
                                <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                    <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                </div>
                            </div>
                        }
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && "ADD"}
                                {!submit && isUpdate && "UPDATE"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Department Master
                            </h2>
                            <div className='flex'>
                                <div>
                                    <button className="h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white mr-4 text-sm" onClick={() => {
                                        setOldURL('');
                                        setOldData({});
                                        setBanner(null);
                                        setIsOpenMain(true);
                                    }}><i class="las la-plus mr-1"></i> ADD DEPARTMENT</button>
                                </div>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Sr No.
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Title
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Index
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                    <th scope="col" className="px-2 py-3"></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    data.length !== 0 && data.map((item, index) => {
                                        return <tr className="hover:bg-gray-100" key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-prime'>
                                                        {(index + 1)}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900 uppercase flex items-center">
                                                    {item.media === undefined && <img src="https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg" alt='Profile Image' className='w-16 h-10 rounded' />}
                                                    {item.media !== undefined && <img src={Object.keys(item.media).length === 0 ? "https://storage.googleapis.com/ish-prod/ish-assets/staff.png" : item.media.type.includes("video") ? fileIcon : item.media.fileurl} alt='Profile Image' className='w-16 h-10 rounded' />}
                                                    <div className='ml-2'>{item.title}</div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900 uppercase">
                                                    {item.disc}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {item.index}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-prime">
                                                    {moment(item.createdAt).format('DD MMM, yyyy')}
                                                </div>
                                            </td>
                                            <td>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-32px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    <DropdownMenuItem>
                                                                        <li className='w-32 cursor-pointer px-2 py-1 rounded-md' onClick={() => {
                                                                            copyLink(item._id);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-clipboard text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Copy Link</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className='w-32 cursor-pointer px-2 py-1 rounded-md' onClick={() => {
                                                                            editMar(item);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-pen text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Edit</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className='w-32 cursor-pointer px-2 py-1 rounded-md' onClick={() => {
                                                                            delMar(item._id, item.media);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-trash text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Delete</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    data.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
