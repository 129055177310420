import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { partnerMeetStateAtom, partnerMeetConfigAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import { Video, VideoOff, Mic, MicOff, Send, Phone, MessageSquareMore, CircleX } from 'lucide-react';

import moment from 'moment';

import { Device } from 'mediasoup-client';

import socket from '../../core/config/socket';

import PaneReview from './pane/PaneReview';

export default function PanelMeet({ partner }) {

    const [meetConfig, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);
    const [meetState, setMeetState] = useRecoilState(partnerMeetStateAtom);

    const [mDevice, setMDevice] = useState(null);
    const [prdTransport, setProducerTransport] = useState(null);
    const [conTransport, setConsumerTransport] = useState(null);

    const [prdAudio, setPrdAudio] = useState(null);
    const [prdVideo, setPrdVideo] = useState(null);

    const [consumerStreams, setConsumerStreams] = useState({});

    const [queuePosition, setQueuePosition] = useState(null);

    const [chats, setChats] = useState([]);

    const [roomid, setRoomID] = useState('');

    const [isStarted, setIsStarted] = useState(false);
    const [isJoin, setIsJoin] = useState(false);
    const [isVid, setIsVid] = useState(true);
    const [isAudio, setIsAudio] = useState(true);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [isPWait, setIsPWait] = useState(false);
    const [isIWait, setIsIWait] = useState(false);

    const [, setMClock] = useState(0);

    const localStreamRef = useRef();
    const chatRef = useRef('');

    const joinQueue = () => {
        let room = { ...meetConfig };
        room.partner = partner;
        room.cid = 'NA';
        socket.emit('createRoom', { room }, (rmid, position) => {
            setQueuePosition(position);
            setRoomID(rmid);
            setIsStarted(true);
            initTransports(rmid);
        });
    }

    const initTransports = async (rmid) => {
        // init producerTransport
        {
            socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsA) => {
                if (paramsA.error) {
                    console.error(paramsA.error)
                    return
                }

                let producerTransport = mDevice.createSendTransport(paramsA);

                producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
                    socket.emit('connectTransport', { roomid: rmid, transport_id: paramsA.id, dtlsParameters }, (res) => {
                        console.log(res);
                    });
                    callback();
                }.bind(this));

                producerTransport.on('produce', async function ({ kind, rtpParameters }, callback, errback) {
                    await socket.emit('produce', {
                        roomid: rmid,
                        producerTransportId: producerTransport.id,
                        kind,
                        rtpParameters
                    }, (res) => {
                        callback({
                            id: res.producer_id
                        });
                    });
                }.bind(this));

                producerTransport.on('connectionstatechange', function (state) {
                    console.log(state);
                    switch (state) {
                        case 'failed':
                            producerTransport.close();
                            break;
                        default:
                            break;
                    }
                }.bind(this));

                setProducerTransport(producerTransport);

                // init consumerTransport
                {
                    socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsB) => {
                        if (paramsB.error) {
                            console.error(paramsB.error)
                            return
                        }

                        let consumerTransport = mDevice.createRecvTransport(paramsB);

                        consumerTransport.on('connect', function ({ dtlsParameters }, callback, errback) {
                            socket.emit('connectTransport', { roomid: rmid, transport_id: consumerTransport.id, dtlsParameters }, (res) => {
                                console.log(res);
                            });
                            callback();
                        }.bind(this));

                        consumerTransport.on('connectionstatechange', async function (state) {
                            console.log('consume state: ', state);
                            switch (state) {
                                case 'failed':
                                    consumerTransport.close();
                                    break;
                                default:
                                    break;
                            }
                        }.bind(this));

                        setConsumerTransport(consumerTransport);

                        socket.emit('getProducers', { roomid: rmid });
                    });
                }
            });
        }
    }

    const initMediaSoup = async (rtpCapabilities) => {
        try {
            let dev = new Device();
            await dev.load({
                routerRtpCapabilities: rtpCapabilities
            });
            setMDevice(dev);
        } catch (error) {
            console.log(error);
            if (error.name === 'UnsupportedError') {
                console.warn('browser not supported');
            }
        }
    }

    const initMeet = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localStreamRef.current.srcObject = stream;
        socket.emit('initHost', {}, (rtpCapabilities) => {
            initMediaSoup(rtpCapabilities);
        });
    }

    const connectSendTransport = () => {
        connectAudio();
        connectVideo();
    }

    const connectAudio = async () => {
        const track = localStreamRef.current.srcObject.getAudioTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdAudio.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdAudio.close();
            setPrdAudio(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdAudio(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdAudio(null);
        });

        setPrdAudio(producer);
    }

    const connectVideo = async () => {
        const track = localStreamRef.current.srcObject.getVideoTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdVideo.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdVideo.close();
            setPrdVideo(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdVideo(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdVideo(null);
        });

        setPrdVideo(producer);
    }

    const toggleVideo = () => {
        if (prdVideo) {
            if (isVid) {
                prdVideo.pause();
            } else {
                prdVideo.resume();
            }
            setIsVid(!isVid);
        }
    }

    const toggleAudio = () => {
        if (prdAudio) {
            if (isAudio) {
                prdAudio.pause();
            } else {
                prdAudio.resume();
            }
            setIsAudio(!isAudio);
        }
    }

    const sendChat = () => {
        const message = chatRef.current.value;
        if (message !== "") {
            let chat = {
                name: meetConfig.name,
                message: message,
                time: moment(Date.now()).toISOString()
            }
            socket.emit('sendMessage', { roomid: roomid, chat });
            chatRef.current.value = "";
        }
    }

    const getConsumeStream = async (producerId, sid) => {
        const { rtpCapabilities } = mDevice;
        socket.emit('consume', { roomid, rtpCapabilities, consumerTransportId: conTransport.id, producerId }, async (param) => {
            const { id, kind, rtpParameters } = param;
            let codecOptions = {}
            const consumer = await conTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions
            });

            if (consumerStreams[sid] !== null) {
                const newStream = new MediaStream();
                newStream.addTrack(consumer.track);
                consumerStreams[sid] = newStream;
            } else {
                consumerStreams[sid].addTrack(consumer.track);
            }

            setConsumerStreams(consumerStreams);
            setMClock(prev => prev + 1);
        });
    }

    const endCall = () => {
        socket.emit('endRoom', { roomid }, () => {
            console.log('Room Ended');
        });
    }

    const onReview = () => {
        setMeetConfig({});
        setMeetState(3);
    }

    const reset = () => {
        setIsReview(true);
    }

    const getLocalCss = () => {
        if (!isJoin) {
            return 'col-span-12';
        } else {
            if (Object.keys(consumerStreams).length > 1) {
                return 'col-span-12 xl:col-span-3 flex xl:block';
            } else {
                return 'absolute w-[22%] right-[20px] bottom-[20px] z-10';
            }
        }
    }

    useEffect(() => {
        initMeet();
        socket.on('queuePosition', (position) => {
            setQueuePosition(position.pos);
            if (position.status === "pwait") {
                setIsPWait(true);
            }
            if (position.status === "iwait") {
                setIsIWait(true);
            }
            if (position.status === "live") {
                setIsJoin(true);
            }
        });
        socket.on('recvChat', (chat) => {
            let chatList = [...chats];
            chatList.push(chat);
            setChats(chatList);
        });
    }, [chats]);

    useEffect(() => {
        if (prdTransport !== null) {
            connectSendTransport();
        }
    }, [prdTransport]);

    useEffect(() => {
        if (mDevice !== null && roomid === '') {
            joinQueue();
        }
    }, [mDevice]);

    useEffect(() => {
        if (roomid !== '') {
            socket.on('endedRooms', ({ romid }) => {
                if (roomid === romid) {
                    reset();
                }
            });
        }
    }, [roomid]);

    useEffect(() => {
        if (conTransport !== null && mDevice !== null) {
            socket.on('newProducers', async function (data) {
                if (data.length !== 0) {
                    let { producer_id, producer_socket_id } = data;
                    if (producer_socket_id) {
                        await getConsumeStream(producer_id, producer_socket_id);
                    }
                }
            }.bind(this));
        }
    }, [conTransport, mDevice]);

    return (
        <>
            {
                isChatOpen && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-full h-screen p-6'>
                        <div className='flex flex-col bg-white'>
                            <div className='p-2 flex items-center justify-between'>
                                <span className='text-lg font-medium'>Chats</span>
                                <div className='w-[20px] h-[20px] flex items-center justify-center' onClick={() => { setIsChatOpen(false) }}>
                                    <CircleX size={18} />
                                </div>
                            </div>
                            <div className='h-[1px] bg-gray-900' ></div>
                            <ScrollArea className="w-full min-h-[80vh] flex-grow bg-gray-100 my-2 p-2 rounded-md">
                                {
                                    chats.map((chat) => {
                                        return <div className='w-full mb-4'>
                                            <div className='w-full mb-2 flex'>
                                                <div className='flex-grow'>
                                                    <h1 className='text-sm font-medium'>{chat.name}</h1>
                                                    <p className='text-xs text-gray-600 mt-1'>{chat.message}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs text-gray-400'>{moment(chat.time).format('hh:mm')}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    })
                                }
                            </ScrollArea>
                            <div className="flex">
                                <input type="text" placeholder="Your Message" className="w-full h-11 rounded-md bg-gray-200 px-4 py-2 text-sm font-sans" required autoComplete="off" ref={chatRef} />
                                <button className='w-12 h-11 bg-prime ml-2 rounded-md text-white flex items-center justify-center' onClick={() => { sendChat(); }}>
                                    <Send size={18} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isReview && <PaneReview id={roomid} name={meetConfig.name} status={isReview} partner={partner} onSubmit={onReview} />}
            <div className='w-full h-full grid grid-cols-12 gap-4'>
                <div className='col-span-12 xl:col-span-8'>
                    <div className='w-full h-full flex xl:items-center xl:justify-center'>
                        <div className='w-full'>
                            <div className='grid grid-cols-12 gap-4 relative'>
                                {
                                    isJoin && Object.keys(consumerStreams).length === 1 && <div className="col-span-12 pt-[160%] xl:pt-[56.25%]">
                                        <video ref={(element) => {
                                            if (element) {
                                                element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                            }
                                        }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                    </div>
                                }
                                {
                                    isJoin && Object.keys(consumerStreams).length > 1 && <div className="col-span-12 xl:hidden">
                                        <div className='w-full pt-[100%] xl:pt-[56.25%] relative'>
                                            <video ref={(element) => {
                                                if (element) {
                                                    element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                                }
                                            }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                        </div>
                                    </div>
                                }
                                <div className={`${getLocalCss()}`}>
                                    <div className={`w-full ${isJoin ? Object.keys(consumerStreams).length === 1 ? "pt-[160%] xl:pt-[56.25%]" : "mx-1 xl:mx-0 my-2 xl:my-0 pt-[30%] xl:pt-[56.25%]" : "pt-[56.25%]"} relative`}>
                                        <video ref={localStreamRef} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                    </div>
                                    {
                                        isJoin && Object.keys(consumerStreams).length > 1 && Object.keys(consumerStreams).map((key, index) => {
                                            if (index === 0) return <div></div>;
                                            return <div className='w-full pt-[30%] mx-1 xl:mx-0 xl:pt-[56.25%] relative my-2'>
                                                <video ref={(element) => {
                                                    if (element) {
                                                        element.srcObject = consumerStreams[key];
                                                    }
                                                }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                            </div>;
                                        })
                                    }
                                </div>
                                {
                                    isJoin && Object.keys(consumerStreams).length > 1 && <div className="col-span-9 hidden xl:block">
                                        <div className='w-full pt-[100%] xl:pt-[56.25%] relative'>
                                            <video ref={(element) => {
                                                if (element) {
                                                    element.srcObject = consumerStreams[Object.keys(consumerStreams)[0]];
                                                }
                                            }} autoPlay playsInline muted className='border-2 border-prime rounded-2xl object-cover xl:object-fill absolute inset-0 w-full h-full' />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='w-full h-[60px] mt-4'>
                                <div className={`flex items-center ${Object.keys(consumerStreams).length > 1 ? "justify-center xl:justify-end" : "justify-center"}`}>
                                    <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isVid ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                                        toggleVideo();
                                    }}>
                                        {isVid && <Video size={18} />}
                                        {!isVid && <VideoOff size={18} />}
                                    </div>
                                    <div className={`w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center ${isAudio ? 'bg-gray-800' : 'bg-red-600'}`} onClick={() => {
                                        toggleAudio();
                                    }}>
                                        {isAudio && <Mic size={18} />}
                                        {!isAudio && <MicOff size={18} />}
                                    </div>
                                    <div className='w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center bg-gray-800 xl:hidden' onClick={() => {
                                        setIsChatOpen(true);
                                    }}>
                                        <MessageSquareMore size={18} />
                                    </div>
                                    <div className='w-10 h-10 cursor-pointer rounded-2xl mx-2 text-white flex items-center justify-center bg-red-600' onClick={() => {
                                        endCall();
                                    }}>
                                        <Phone size={18} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (isStarted && !isJoin) && <div className='col-span-12 xl:col-span-4 p-2 flex flex-col items-center xl:justify-center'>
                        <div className='w-full bg-gray-50 rounded-xl border border-dashed border-prime p-4'>
                            <h1 className='text-2xl text-center font-medium'>Please Wait</h1>
                            {(!isPWait && !isIWait) && <h1 className='text-sm text-prime text-center mt-6 font-medium'>Your position in queue is <u> {queuePosition === 1 ? "Next" : queuePosition} in line</u></h1>}
                            {isPWait && <h1 className='text-sm text-prime text-center mt-6 font-medium'>Wating for partner to join</h1>}
                            {isIWait && <h1 className='text-sm text-prime text-center mt-6 font-medium'>Wating for interpreter to join</h1>}
                        </div>
                    </div>
                }
                {
                    isJoin && <div className='col-span-4 py-4 hidden xl:block'>
                        <div className='bg-gray-50 h-full max-h-[60vh] rounded-xl flex flex-col p-6 border'>
                            <div className='p-2'>
                                <span className='text-lg font-medium'>Chats</span>
                            </div>
                            <div className='h-[1px] bg-gray-900' ></div>
                            <ScrollArea className="w-full min-h-[40vh] flex-grow bg-gray-100 my-2 p-2 rounded-md">
                                {
                                    chats.map((chat) => {
                                        return <div className='w-full mb-4'>
                                            <div className='w-full mb-2 flex'>
                                                <div className='flex-grow'>
                                                    <h1 className='text-sm font-medium'>{chat.name}</h1>
                                                    <p className='text-xs text-gray-600 mt-1'>{chat.message}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs text-gray-400'>{moment(chat.time).format('hh:mm')}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    })
                                }
                            </ScrollArea>
                            <div className="flex">
                                <input type="text" placeholder="Your Message" className="w-full h-11 rounded-md bg-gray-200 px-4 py-2 text-sm font-sans" required autoComplete="off" ref={chatRef} />
                                <button className='w-12 h-11 bg-prime ml-2 rounded-md text-white flex items-center justify-center' onClick={() => { sendChat(); }}>
                                    <Send size={18} />
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
