import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import parse from 'html-react-parser';
import Lottie from 'lottie-react';

import ServicePartner from 'services/servicePartner';

import LayoutMeet from 'layouts/LayoutMeet';

import animPage from '../assets/anim/anim-pageload.json';

export default function PagePartner() {

    const { page } = useParams();

    const [data, setData] = useState({});

    const [headHtml, setHeadHtml] = useState("");
    const [footHtml, setFootHtml] = useState("");

    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);

    const partnerService = new ServicePartner();

    const gotoLogin = () => {
        window.location.href = 'https://app.ishcallsign.com';
    }

    const loadData = () => {
        (async function () {
            let body = {
                "link": page
            };
            var res = await partnerService.checkLink(body, {});
            if (res['status']) {
                if (res.data) {
                    let subs = res.data.subscription;
                    setData(res.data);
                    if (subs.pageData === "") {
                        setHeadHtml("<div className='w-full min-h-[16vh] bg-blue-100'></div>");
                        setFootHtml("<div className='w-full min-h-[40vh] bg-blue-100'></div>");
                    } else {
                        const payload = JSON.parse(subs.pageData);
                        setHeadHtml(payload.head);
                        setFootHtml(payload.foot);
                        injectCSS(payload.css);
                    }
                } else {
                    setRedirect(true);
                }
            } else {
                setRedirect(true);
            }
            setLoading(false);
        })();
    }

    const injectCSS = (cssString) => {
        const style = document.createElement('style');
        style.innerHTML = cssString;
        document.head.appendChild(style);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Dialog open={redirect} onClose={() => setRedirect(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex flex-col items-center justify-center'>
                        <p className='my-6 text-yellow-600 font-medium text-lg text-center px-12'>This partner link your trying to connect is ethier moved to some other link or invalid</p>
                        <button type="button" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" onClick={() => { gotoLogin(); }}>
                            OK
                        </button>
                    </div>
                </div>
            </Dialog>
            <ScrollArea className="w-full xl:h-screen">
                {parse(headHtml)}
                {
                    loading && <div className='w-full h-[84vh] md:h-[74vh] px-[2%] md:px-[10%] bg-gray-100 flex items-center justify-center'>
                        <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                    </div>
                }
                {!loading && <LayoutMeet partner={data} />}
                {parse(footHtml)}
            </ScrollArea>
        </>
    )
}
