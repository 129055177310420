import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOverview {
    lookupService = new APIServiceLookUp();

    async getTicketCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getTicketCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTicketPCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getTicketPCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getStaffCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}overview/getStaffCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}