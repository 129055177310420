import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom, partnerMeetStateAtom, partnerMeetConfigAtom } from '../../core/config/atoms';

import { motion } from 'framer-motion';

import ServiceDept from 'services/serviceDept';

export default function PanelSelectDept({ partner }) {

    const [token] = useRecoilState(tokenAtom);

    const constraintsRef = useRef(null);

    const [, setMeetState] = useRecoilState(partnerMeetStateAtom);
    const [, setMeetConfig] = useRecoilState(partnerMeetConfigAtom);

    const [deptOptions, setDeptOptions] = useState([]);

    const [vidurl, setVidurl] = useState('');

    const [isPopup, setIsPopup] = useState(false);

    const deptService = new ServiceDept();

    const selectDept = (dept) => {
        setMeetConfig({ 'dept': dept });
        setMeetState(1);
    }

    const loadData = () => {
        (async function () {
            var body = {
                "pid": partner._id,
            }
            var deptRes = await deptService.getAllDept(body, token);
            const sortedData = deptRes.data.slice().sort((a, b) => {
                return a.index - b.index;
            });
            setDeptOptions(sortedData);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className='w-full h-full flex flex-col justify-start' ref={constraintsRef}>
            {
                isPopup && <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }} className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className='absolute text-4xl text-white top-[20px] right-[20px] cursor-pointer' onClick={() => {
                        setIsPopup(false);
                        setVidurl('');
                    }}>
                        <i className="las la-window-close"></i>
                    </div>
                    <motion.div drag dragConstraints={constraintsRef} className='w-[90%] xl:w-[40%] absolute z-[50] shadow-xl rounded-xl overflow-hidden'>
                        <div className="w-full pt-[56.25%] relative">
                            <video src={vidurl} autoPlay className="object-cover absolute inset-0 w-full h-full" />
                        </div>
                    </motion.div>
                </motion.div>
            }
            <h1 className='text-2xl font-medium text-center my-6'>Please select department</h1>
            <div className='w-full p-2 lg:p-10 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-6 gap-8'>
                <div className='col-span-2 bg-white rounded border hover:shadow-md cursor-pointer'>
                    <img src='https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg' alt='banner' />
                    <div className='p-2' onClick={() => { selectDept({ title: 'general', disc: 'General enquiry department', id: 'na', tag: '1' }) }}>
                        <h1 className='text-lg text-prime font-medium font-sans'>General Enquiry</h1>
                        <p className='text-sm font-sans text-gray-600'>Department for any general enquiry</p>
                    </div>
                </div>
                {
                    deptOptions.map((dept) => {
                        return <div className='col-span-2 bg-white rounded border hover:shadow-md cursor-pointer' key={dept._id}>
                            {(dept.media === undefined || Object.keys(dept.media) === 0) && <img src='https://storage.googleapis.com/ish-prod/ish-assets/banner.jpg' alt='banner' />}
                            {
                                dept.media !== undefined && Object.keys(dept.media) !== 0 && <div>
                                    {
                                        dept.media.type.includes("video") ? <div className='w-full pt-[56.25%] relative' onClick={() => {
                                            if (dept.media !== undefined) {
                                                if (dept.media.type.includes('video')) {
                                                    setVidurl(dept.media.fileurl)
                                                    setIsPopup(true)
                                                }
                                            }
                                        }} >
                                            <video src={dept.media.fileurl} className='object-cover absolute inset-0 w-full h-full' />
                                        </div> : <img src={dept.media.fileurl} alt='banner' />
                                    }
                                </div>
                            }
                            <div className='p-2' onClick={() => { selectDept({ title: dept.title, disc: dept.disc, id: dept._id, tag: '2' }) }}>
                                <h1 className='text-lg text-prime font-medium font-sans'>{dept.title}</h1>
                                <p className='text-sm font-sans text-gray-600'>{dept.disc}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div >
    )
}
