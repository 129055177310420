import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceSubscription {
    lookupService = new APIServiceLookUp();

    async updatePageStyle(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subscription/updatePageStyle`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getSubscriptionById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subscription/getSubscriptionById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}